/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.140.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { SystemVersionRest } from '../models';
/**
 * VersionResourceApi - axios parameter creator
 * @export
 */
export const VersionResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VersionResourceApi - functional programming interface
 * @export
 */
export const VersionResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VersionResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVersionInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemVersionRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVersionInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VersionResourceApi.getVersionInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VersionResourceApi - factory interface
 * @export
 */
export const VersionResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VersionResourceApiFp(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> isAuthenticated()
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVersionInfo(options?: any): AxiosPromise<SystemVersionRest> {
            return localVarFp.getVersionInfo(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VersionResourceApi - object-oriented interface
 * @export
 * @class VersionResourceApi
 * @extends {BaseAPI}
 */
export class VersionResourceApi extends BaseAPI {
    /**
     * <b>Benötigte Rechte:</b> isAuthenticated()
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VersionResourceApi
     */
    public getVersionInfo(options?: RawAxiosRequestConfig) {
        return VersionResourceApiFp(this.configuration).getVersionInfo(options).then((request) => request(this.axios, this.basePath));
    }
}

