/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.140.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { MonitoringrelevanzRest } from '../models';
// @ts-ignore
import { VerfahrenEinstellungenRest } from '../models';
/**
 * VerfahrenEinstellungenRessourceApi - axios parameter creator
 * @export
 */
export const VerfahrenEinstellungenRessourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinstellungen: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getEinstellungen', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/einstellungen`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonitoringRelevanzen: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getMonitoringRelevanzen', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/einstellungen/monitoringrelevanz`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Im Request nicht definierte Properties werden nicht überschrieben.  Mit <code>null</code> angegebene Properties werden auch auf <code>null</code> gesetzt.</br></br><b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
         * @summary Im Request nicht definierte Properties werden nicht überschrieben.
         * @param {VerfahrenEinstellungenRest} verfahrenEinstellungenRest 
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEinstellungen: async (verfahrenEinstellungenRest: VerfahrenEinstellungenRest, planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verfahrenEinstellungenRest' is not null or undefined
            assertParamExists('setEinstellungen', 'verfahrenEinstellungenRest', verfahrenEinstellungenRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('setEinstellungen', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/einstellungen`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verfahrenEinstellungenRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
         * @param {MonitoringrelevanzRest} monitoringrelevanzRest 
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMonitoringRelevanz: async (monitoringrelevanzRest: MonitoringrelevanzRest, planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'monitoringrelevanzRest' is not null or undefined
            assertParamExists('setMonitoringRelevanz', 'monitoringrelevanzRest', monitoringrelevanzRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('setMonitoringRelevanz', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/einstellungen/monitoringrelevanz`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monitoringrelevanzRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VerfahrenEinstellungenRessourceApi - functional programming interface
 * @export
 */
export const VerfahrenEinstellungenRessourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VerfahrenEinstellungenRessourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEinstellungen(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerfahrenEinstellungenRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEinstellungen(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenEinstellungenRessourceApi.getEinstellungen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMonitoringRelevanzen(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonitoringrelevanzRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMonitoringRelevanzen(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenEinstellungenRessourceApi.getMonitoringRelevanzen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Im Request nicht definierte Properties werden nicht überschrieben.  Mit <code>null</code> angegebene Properties werden auch auf <code>null</code> gesetzt.</br></br><b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
         * @summary Im Request nicht definierte Properties werden nicht überschrieben.
         * @param {VerfahrenEinstellungenRest} verfahrenEinstellungenRest 
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setEinstellungen(verfahrenEinstellungenRest: VerfahrenEinstellungenRest, planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VerfahrenEinstellungenRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setEinstellungen(verfahrenEinstellungenRest, planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenEinstellungenRessourceApi.setEinstellungen']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
         * @param {MonitoringrelevanzRest} monitoringrelevanzRest 
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setMonitoringRelevanz(monitoringrelevanzRest: MonitoringrelevanzRest, planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MonitoringrelevanzRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setMonitoringRelevanz(monitoringrelevanzRest, planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['VerfahrenEinstellungenRessourceApi.setMonitoringRelevanz']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * VerfahrenEinstellungenRessourceApi - factory interface
 * @export
 */
export const VerfahrenEinstellungenRessourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VerfahrenEinstellungenRessourceApiFp(configuration)
    return {
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEinstellungen(planID: string, options?: any): AxiosPromise<VerfahrenEinstellungenRest> {
            return localVarFp.getEinstellungen(planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMonitoringRelevanzen(planID: string, options?: any): AxiosPromise<Array<MonitoringrelevanzRest>> {
            return localVarFp.getMonitoringRelevanzen(planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Im Request nicht definierte Properties werden nicht überschrieben.  Mit <code>null</code> angegebene Properties werden auch auf <code>null</code> gesetzt.</br></br><b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
         * @summary Im Request nicht definierte Properties werden nicht überschrieben.
         * @param {VerfahrenEinstellungenRest} verfahrenEinstellungenRest 
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setEinstellungen(verfahrenEinstellungenRest: VerfahrenEinstellungenRest, planID: string, options?: any): AxiosPromise<VerfahrenEinstellungenRest> {
            return localVarFp.setEinstellungen(verfahrenEinstellungenRest, planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
         * @param {MonitoringrelevanzRest} monitoringrelevanzRest 
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setMonitoringRelevanz(monitoringrelevanzRest: MonitoringrelevanzRest, planID: string, options?: any): AxiosPromise<Array<MonitoringrelevanzRest>> {
            return localVarFp.setMonitoringRelevanz(monitoringrelevanzRest, planID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VerfahrenEinstellungenRessourceApi - object-oriented interface
 * @export
 * @class VerfahrenEinstellungenRessourceApi
 * @extends {BaseAPI}
 */
export class VerfahrenEinstellungenRessourceApi extends BaseAPI {
    /**
     * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
     * @param {string} planID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenEinstellungenRessourceApi
     */
    public getEinstellungen(planID: string, options?: RawAxiosRequestConfig) {
        return VerfahrenEinstellungenRessourceApiFp(this.configuration).getEinstellungen(planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
     * @param {string} planID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenEinstellungenRessourceApi
     */
    public getMonitoringRelevanzen(planID: string, options?: RawAxiosRequestConfig) {
        return VerfahrenEinstellungenRessourceApiFp(this.configuration).getMonitoringRelevanzen(planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Im Request nicht definierte Properties werden nicht überschrieben.  Mit <code>null</code> angegebene Properties werden auch auf <code>null</code> gesetzt.</br></br><b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
     * @summary Im Request nicht definierte Properties werden nicht überschrieben.
     * @param {VerfahrenEinstellungenRest} verfahrenEinstellungenRest 
     * @param {string} planID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenEinstellungenRessourceApi
     */
    public setEinstellungen(verfahrenEinstellungenRest: VerfahrenEinstellungenRest, planID: string, options?: RawAxiosRequestConfig) {
        return VerfahrenEinstellungenRessourceApiFp(this.configuration).setEinstellungen(verfahrenEinstellungenRest, planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> VERFAHREN_EINSTELLUNGEN or ADMIN
     * @param {MonitoringrelevanzRest} monitoringrelevanzRest 
     * @param {string} planID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VerfahrenEinstellungenRessourceApi
     */
    public setMonitoringRelevanz(monitoringrelevanzRest: MonitoringrelevanzRest, planID: string, options?: RawAxiosRequestConfig) {
        return VerfahrenEinstellungenRessourceApiFp(this.configuration).setMonitoringRelevanz(monitoringrelevanzRest, planID, options).then((request) => request(this.axios, this.basePath));
    }
}

