/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.140.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { FindActionItem200Response } from '../models';
/**
 * ActionItemResourceApi - axios parameter creator
 * @export
 */
export const ActionItemResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b> Get one ActionItem</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @summary Get one ActionItem
         * @param {string} planID planID
         * @param {string} actionitemID actionitemID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findActionItem: async (planID: string, actionitemID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('findActionItem', 'planID', planID)
            // verify required parameter 'actionitemID' is not null or undefined
            assertParamExists('findActionItem', 'actionitemID', actionitemID)
            const localVarPath = `/verfahren/{planID}/actionitems/{actionitemID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"actionitemID"}}`, encodeURIComponent(String(actionitemID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Aktualisiere ActionItem mit einem oder mehreren optionalen Multipart-Files (z.B. doc, pdf, ...). Das eigentliche Hochladen  ist implementiert in  </br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @summary Aktualisiere ActionItem mit einem oder mehreren optionalen Multipart-Files (z.B. doc, pdf, ...). Das eigentliche Hochladen  ist implementiert in  
         * @param {FindActionItem200Response} actionitem 
         * @param {string} planID ID des Verfahrens
         * @param {string} actionitemID ID des ActionItems
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActionItem: async (actionitem: FindActionItem200Response, planID: string, actionitemID: string, files?: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'actionitem' is not null or undefined
            assertParamExists('updateActionItem', 'actionitem', actionitem)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('updateActionItem', 'planID', planID)
            // verify required parameter 'actionitemID' is not null or undefined
            assertParamExists('updateActionItem', 'actionitemID', actionitemID)
            const localVarPath = `/verfahren/{planID}/actionitems/{actionitemID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"actionitemID"}}`, encodeURIComponent(String(actionitemID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (actionitem !== undefined) { 
                localVarFormParams.append('actionitem', new Blob([JSON.stringify(actionitem)], { type: "application/json", }));
            }
                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActionItemResourceApi - functional programming interface
 * @export
 */
export const ActionItemResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActionItemResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Get one ActionItem</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @summary Get one ActionItem
         * @param {string} planID planID
         * @param {string} actionitemID actionitemID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findActionItem(planID: string, actionitemID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindActionItem200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findActionItem(planID, actionitemID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActionItemResourceApi.findActionItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Aktualisiere ActionItem mit einem oder mehreren optionalen Multipart-Files (z.B. doc, pdf, ...). Das eigentliche Hochladen  ist implementiert in  </br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @summary Aktualisiere ActionItem mit einem oder mehreren optionalen Multipart-Files (z.B. doc, pdf, ...). Das eigentliche Hochladen  ist implementiert in  
         * @param {FindActionItem200Response} actionitem 
         * @param {string} planID ID des Verfahrens
         * @param {string} actionitemID ID des ActionItems
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateActionItem(actionitem: FindActionItem200Response, planID: string, actionitemID: string, files?: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FindActionItem200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateActionItem(actionitem, planID, actionitemID, files, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActionItemResourceApi.updateActionItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ActionItemResourceApi - factory interface
 * @export
 */
export const ActionItemResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActionItemResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Get one ActionItem</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @summary Get one ActionItem
         * @param {string} planID planID
         * @param {string} actionitemID actionitemID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findActionItem(planID: string, actionitemID: string, options?: any): AxiosPromise<FindActionItem200Response> {
            return localVarFp.findActionItem(planID, actionitemID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Aktualisiere ActionItem mit einem oder mehreren optionalen Multipart-Files (z.B. doc, pdf, ...). Das eigentliche Hochladen  ist implementiert in  </br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
         * @summary Aktualisiere ActionItem mit einem oder mehreren optionalen Multipart-Files (z.B. doc, pdf, ...). Das eigentliche Hochladen  ist implementiert in  
         * @param {FindActionItem200Response} actionitem 
         * @param {string} planID ID des Verfahrens
         * @param {string} actionitemID ID des ActionItems
         * @param {Array<File>} [files] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateActionItem(actionitem: FindActionItem200Response, planID: string, actionitemID: string, files?: Array<File>, options?: any): AxiosPromise<FindActionItem200Response> {
            return localVarFp.updateActionItem(actionitem, planID, actionitemID, files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActionItemResourceApi - object-oriented interface
 * @export
 * @class ActionItemResourceApi
 * @extends {BaseAPI}
 */
export class ActionItemResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b> Get one ActionItem</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
     * @summary Get one ActionItem
     * @param {string} planID planID
     * @param {string} actionitemID actionitemID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionItemResourceApi
     */
    public findActionItem(planID: string, actionitemID: string, options?: RawAxiosRequestConfig) {
        return ActionItemResourceApiFp(this.configuration).findActionItem(planID, actionitemID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Aktualisiere ActionItem mit einem oder mehreren optionalen Multipart-Files (z.B. doc, pdf, ...). Das eigentliche Hochladen  ist implementiert in  </br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_WRITE or ADMIN
     * @summary Aktualisiere ActionItem mit einem oder mehreren optionalen Multipart-Files (z.B. doc, pdf, ...). Das eigentliche Hochladen  ist implementiert in  
     * @param {FindActionItem200Response} actionitem 
     * @param {string} planID ID des Verfahrens
     * @param {string} actionitemID ID des ActionItems
     * @param {Array<File>} [files] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActionItemResourceApi
     */
    public updateActionItem(actionitem: FindActionItem200Response, planID: string, actionitemID: string, files?: Array<File>, options?: RawAxiosRequestConfig) {
        return ActionItemResourceApiFp(this.configuration).updateActionItem(actionitem, planID, actionitemID, files, options).then((request) => request(this.axios, this.basePath));
    }
}

