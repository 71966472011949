/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.140.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { CodeVerfahrensunterlagetyp } from '../models';
// @ts-ignore
import { CodeVormerkung } from '../models';
// @ts-ignore
import { DokumentEditRest } from '../models';
// @ts-ignore
import { DokumentNeuRest } from '../models';
// @ts-ignore
import { DokumentRest } from '../models';
// @ts-ignore
import { DokumentSucheFilter } from '../models';
// @ts-ignore
import { DokumentVormerkungRest } from '../models';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { FindActionItem200Response } from '../models';
// @ts-ignore
import { GetDokumentDetail200Response } from '../models';
// @ts-ignore
import { VerfahrenDokumentRest } from '../models';
/**
 * DokumentResourceApi - axios parameter creator
 * @export
 */
export const DokumentResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b> Loesche an mehrenen Dokumenten den Dokumentstatus (5694).</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Loesche an mehrenen Dokumenten den Dokumentstatus (5694).
         * @param {string} planID planID des Verfahrens
         * @param {string} codeDokumentstatus des zu loeschenden Dokumentstatus
         * @param {Array<string>} fileIDs fileIds der Dokumente
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDokumentenStatus: async (planID: string, codeDokumentstatus: string, fileIDs: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('deleteDokumentenStatus', 'planID', planID)
            // verify required parameter 'codeDokumentstatus' is not null or undefined
            assertParamExists('deleteDokumentenStatus', 'codeDokumentstatus', codeDokumentstatus)
            // verify required parameter 'fileIDs' is not null or undefined
            assertParamExists('deleteDokumentenStatus', 'fileIDs', fileIDs)
            const localVarPath = `/verfahren/{planID}/dokumente/setzedokumentstatus/{codeDokumentstatus}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"codeDokumentstatus"}}`, encodeURIComponent(String(codeDokumentstatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fileIDs) {
                localVarQueryParameter['fileIDs'] = fileIDs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Loeschen aller Dokumente, Dokumentstatus 1800 (Papierkorb). Entgültiges Loeschen von Dokumenten mit Dokumentstatus 1800 am  Verfahren.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Loeschen aller Dokumente, Dokumentstatus 1800 (Papierkorb).
         * @param {string} planID PlanID des Verfahrens
         * @param {boolean} [force] force wenn Dokment final gelöscht werden soll.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteForceAllDokumenteOrSetDokumentStatusGeloescht: async (planID: string, force?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('deleteForceAllDokumenteOrSetDokumentStatusGeloescht', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/dokumente`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Loeschen eines Dokuments, Dokumentstatus 1800 (Papierkorb). Entgültiges Loeschen von Dokumenten mit Dokumentstatus 1800 am  Verfahren.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Loeschen eines Dokuments, Dokumentstatus 1800 (Papierkorb).
         * @param {string} planID PlanID des Verfahrens
         * @param {string} fileID FileID des Dokuments
         * @param {boolean} [force] force wenn Dokment final gelöscht werden soll.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteForceSingleDokumentOrSetDokumentStatusGeloescht: async (planID: string, fileID: string, force?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('deleteForceSingleDokumentOrSetDokumentStatusGeloescht', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('deleteForceSingleDokumentOrSetDokumentStatusGeloescht', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/dokumente/{fileID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} verfahrenNameInfo 
         * @param {string} fileID 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVeroeffentlichtesDokument: async (verfahrenNameInfo: string, fileID: string, fileName: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verfahrenNameInfo' is not null or undefined
            assertParamExists('downloadVeroeffentlichtesDokument', 'verfahrenNameInfo', verfahrenNameInfo)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('downloadVeroeffentlichtesDokument', 'fileID', fileID)
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('downloadVeroeffentlichtesDokument', 'fileName', fileName)
            const localVarPath = `/s3/{verfahrenNameInfo}/dokumente/{fileID}/{fileName}`
                .replace(`{${"verfahrenNameInfo"}}`, encodeURIComponent(String(verfahrenNameInfo)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)))
                .replace(`{${"fileName"}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Erzeugt eine Datei aus einer Vorlage, welche anhand eines Code-Musterdokuments ermittelt wird. Im default-Fall wird ein  Word-Dokument generiert, optional kann mit dem Parameter <code>format</code> die Erzeugung eines PDF-Typs festgelegt werden. Im  speziellen wird damit das GA-Papier generiert. Das Dokument wird nicht in der Dokumentenbibliothek gespeichert, sondern  zum Browser gestreamt.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Erzeugt eine Datei aus einer Vorlage, welche anhand eines Code-Musterdokuments ermittelt wird.
         * @param {string} planID planID des Verfahrens
         * @param {string} codeMusterdokument Code-Musterdokument
         * @param {string} [format] Optionale Angabe eines PDF-Typs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erzeugeDokumentDateiAusSystemparameterGefilterterVorlage: async (planID: string, codeMusterdokument: string, format?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('erzeugeDokumentDateiAusSystemparameterGefilterterVorlage', 'planID', planID)
            // verify required parameter 'codeMusterdokument' is not null or undefined
            assertParamExists('erzeugeDokumentDateiAusSystemparameterGefilterterVorlage', 'codeMusterdokument', codeMusterdokument)
            const localVarPath = `/verfahren/{planID}/dokumente/musterdokument/{codeMusterdokument}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"codeMusterdokument"}}`, encodeURIComponent(String(codeMusterdokument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} codeMusterdokument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erzeugeDokumentDateiAusVorlage: async (planID: string, codeMusterdokument: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('erzeugeDokumentDateiAusVorlage', 'planID', planID)
            // verify required parameter 'codeMusterdokument' is not null or undefined
            assertParamExists('erzeugeDokumentDateiAusVorlage', 'codeMusterdokument', codeMusterdokument)
            const localVarPath = `/verfahren/{planID}/dokumente/{codeMusterdokument}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"codeMusterdokument"}}`, encodeURIComponent(String(codeMusterdokument)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} codeMusterdokument 
         * @param {string} codeVerfahrensteilschritte 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erzeugeDokumentDateiAusVorlage1: async (planID: string, codeMusterdokument: string, codeVerfahrensteilschritte: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('erzeugeDokumentDateiAusVorlage1', 'planID', planID)
            // verify required parameter 'codeMusterdokument' is not null or undefined
            assertParamExists('erzeugeDokumentDateiAusVorlage1', 'codeMusterdokument', codeMusterdokument)
            // verify required parameter 'codeVerfahrensteilschritte' is not null or undefined
            assertParamExists('erzeugeDokumentDateiAusVorlage1', 'codeVerfahrensteilschritte', codeVerfahrensteilschritte)
            const localVarPath = `/verfahren/{planID}/dokumente/{codeMusterdokument}/{codeVerfahrensteilschritte}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"codeMusterdokument"}}`, encodeURIComponent(String(codeMusterdokument)))
                .replace(`{${"codeVerfahrensteilschritte"}}`, encodeURIComponent(String(codeVerfahrensteilschritte)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> erzeugt Ordner als zip</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary erzeugt Ordner als zip
         * @param {string} planID planID
         * @param {string} ordnername ordnername
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erzeugeOrdner: async (planID: string, ordnername: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('erzeugeOrdner', 'planID', planID)
            // verify required parameter 'ordnername' is not null or undefined
            assertParamExists('erzeugeOrdner', 'ordnername', ordnername)
            const localVarPath = `/verfahren/{planID}/dokumente/ordner/{ordnername}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"ordnername"}}`, encodeURIComponent(String(ordnername)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert eine Liste der ActionItems welche auf das Dokument referenzieren.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @summary Liefert eine Liste der ActionItems welche auf das Dokument referenzieren.
         * @param {string} planID die planID des  Verfahrens
         * @param {string} fileID die fileID des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findActionItems: async (planID: string, fileID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('findActionItems', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('findActionItems', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/dokumente/{fileID}/actionitems`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Download eines Binary  <p>  Antwort der K1 auf diesen Endpoint bitte noch den Header Content-Disposition = attachment; filename=“datei_xyz.endung“  <p>  Annotation @Transactional wird hier verwendet, weil auf einen LOB zugegriffen wird, der auf verschiedene reocrds verteilt  sein kann.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Download eines Binary  
         * @param {string} planID planID
         * @param {string} fileID dokument.getFileId
         * @param {string} [version] version
         * @param {string} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumentDatei: async (planID: string, fileID: string, version?: string, format?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getDokumentDatei', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('getDokumentDatei', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/dokumente/{fileID}/datei`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (version !== undefined) {
                localVarQueryParameter['version'] = version;
            }

            if (format !== undefined) {
                localVarQueryParameter['format'] = format;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert die Metadaten zu einem Dokument.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Liefert die Metadaten zu einem Dokument.
         * @param {string} planID Plan des Verfahrens
         * @param {string} fileID FileID des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumentDetail: async (planID: string, fileID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getDokumentDetail', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('getDokumentDetail', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/dokumente/{fileID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Auflistung aller Dokumente des Verfahrens.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Auflistung aller Dokumente des Verfahrens.
         * @param {string} planID planID des {@link Verfahren Verfahren}s
         * @param {string} [suchBegriff] suchBegriff
         * @param {Array<string>} [dateiNamen] dateiName
         * @param {Array<string>} [bearbeiter] bearbeiter
         * @param {Array<string>} [datum] datum
         * @param {Array<string>} [codeDokumentstatus] codeDokumentstatus
         * @param {Array<string>} [versionen] version
         * @param {Array<CodeVormerkung>} [codeVormerkung] codeVormerkung
         * @param {Array<CodeVerfahrensunterlagetyp>} [codeVerfahrensunterlagetyp] codeVerfahrensunterlagetyp
         * @param {Array<string>} [fileID] fileIDs
         * @param {Array<GetDokumentUebersichtKlassifizierung>} [klassifizierung] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumentUebersicht: async (planID: string, suchBegriff?: string, dateiNamen?: Array<string>, bearbeiter?: Array<string>, datum?: Array<string>, codeDokumentstatus?: Array<string>, versionen?: Array<string>, codeVormerkung?: Array<CodeVormerkung>, codeVerfahrensunterlagetyp?: Array<CodeVerfahrensunterlagetyp>, fileID?: Array<string>, klassifizierung?: Array<GetDokumentUebersichtKlassifizierung>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getDokumentUebersicht', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/dokumente`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (suchBegriff !== undefined) {
                localVarQueryParameter['suchBegriff'] = suchBegriff;
            }

            if (dateiNamen) {
                localVarQueryParameter['dateiNamen'] = dateiNamen;
            }

            if (bearbeiter) {
                localVarQueryParameter['bearbeiter'] = bearbeiter;
            }

            if (datum) {
                localVarQueryParameter['datum'] = datum;
            }

            if (codeDokumentstatus) {
                localVarQueryParameter['codeDokumentstatus'] = codeDokumentstatus;
            }

            if (versionen) {
                localVarQueryParameter['versionen'] = versionen;
            }

            if (codeVormerkung) {
                localVarQueryParameter['codeVormerkung'] = codeVormerkung;
            }

            if (codeVerfahrensunterlagetyp) {
                localVarQueryParameter['codeVerfahrensunterlagetyp'] = codeVerfahrensunterlagetyp;
            }

            if (fileID) {
                localVarQueryParameter['fileID'] = fileID;
            }

            if (klassifizierung) {
                localVarQueryParameter['klassifizierung'] = klassifizierung;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Abrufen einer Filterbelegung.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Abrufen einer Filterbelegung.
         * @param {string} planID PlanID des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterbelegung: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('getFilterbelegung', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/dokumente/filterbelegung`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Hört auf Server-Sent-Events mit {@link DokumentRest DokumentRest} als data.  Die Events werden bei Updates von Dokumenten durch WebDav gesendet</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Hört auf Server-Sent-Events mit {@link DokumentRest DokumentRest} als data.
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listenForDokumentUpdates: async (planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('listenForDokumentUpdates', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/dokumente/updates`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Löscht eine Vormerkung an einem Dokument.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Löscht eine Vormerkung an einem Dokument.
         * @param {string} planID planID des Verfahrens
         * @param {string} fileID fileID des Dokuments
         * @param {string} codeVormerkung die zu löschende Vormerkung
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loescheDokumentVormerkung: async (planID: string, fileID: string, codeVormerkung: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('loescheDokumentVormerkung', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('loescheDokumentVormerkung', 'fileID', fileID)
            // verify required parameter 'codeVormerkung' is not null or undefined
            assertParamExists('loescheDokumentVormerkung', 'codeVormerkung', codeVormerkung)
            const localVarPath = `/verfahren/{planID}/dokumente/{fileID}/vormerken/{codeVormerkung}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)))
                .replace(`{${"codeVormerkung"}}`, encodeURIComponent(String(codeVormerkung)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishDokument: async (planID: string, fileID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('publishDokument', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('publishDokument', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/dokumente/{fileID}/veroeffentlichen`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Setzt an einer Liste von fileIDs eine übergebene Vormerkung</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Setzt an einer Liste von fileIDs eine übergebene Vormerkung
         * @param {DokumentVormerkungRest} dokumentVormerkungRest Liste der ID\&#39;s und die Vormerkung
         * @param {string} planID planID des Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDokumentVormerkung: async (dokumentVormerkungRest: DokumentVormerkungRest, planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dokumentVormerkungRest' is not null or undefined
            assertParamExists('setDokumentVormerkung', 'dokumentVormerkungRest', dokumentVormerkungRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('setDokumentVormerkung', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/dokumente/vormerken`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dokumentVormerkungRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Setzt an einem Dokument den Dokumentstatus.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Setzt an einem Dokument den Dokumentstatus.
         * @param {string} planID planID des Verfahrens
         * @param {string} fileID fileId des Dokuments
         * @param {string} codeDokumentstatus der zu setzende Dokumentstatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setzeDokumentStatus: async (planID: string, fileID: string, codeDokumentstatus: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('setzeDokumentStatus', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('setzeDokumentStatus', 'fileID', fileID)
            // verify required parameter 'codeDokumentstatus' is not null or undefined
            assertParamExists('setzeDokumentStatus', 'codeDokumentstatus', codeDokumentstatus)
            const localVarPath = `/verfahren/{planID}/dokumente/{fileID}/setzedokumentstatus/{codeDokumentstatus}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)))
                .replace(`{${"codeDokumentstatus"}}`, encodeURIComponent(String(codeDokumentstatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Setzt an mehrenen Dokumenten den Dokumentstatus (5694).</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Setzt an mehrenen Dokumenten den Dokumentstatus (5694).
         * @param {string} planID planID des Verfahrens
         * @param {string} codeDokumentstatus der zu setzende Dokumentstatus
         * @param {Array<string>} fileIDs fileIds der Dokumente
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setzeDokumentenStatus: async (planID: string, codeDokumentstatus: string, fileIDs: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('setzeDokumentenStatus', 'planID', planID)
            // verify required parameter 'codeDokumentstatus' is not null or undefined
            assertParamExists('setzeDokumentenStatus', 'codeDokumentstatus', codeDokumentstatus)
            // verify required parameter 'fileIDs' is not null or undefined
            assertParamExists('setzeDokumentenStatus', 'fileIDs', fileIDs)
            const localVarPath = `/verfahren/{planID}/dokumente/setzedokumentstatus/{codeDokumentstatus}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"codeDokumentstatus"}}`, encodeURIComponent(String(codeDokumentstatus)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fileIDs) {
                localVarQueryParameter['fileIDs'] = fileIDs;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {Array<VerfahrenDokumentRest>} verfahrenDokumentRest 
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareDokument: async (verfahrenDokumentRest: Array<VerfahrenDokumentRest>, planID: string, fileID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verfahrenDokumentRest' is not null or undefined
            assertParamExists('shareDokument', 'verfahrenDokumentRest', verfahrenDokumentRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('shareDokument', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('shareDokument', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/dokumente/{fileID}/add-verfahren`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verfahrenDokumentRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishDokument: async (planID: string, fileID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('unpublishDokument', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('unpublishDokument', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/dokumente/{fileID}/zurueckziehen`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {Array<VerfahrenDokumentRest>} verfahrenDokumentRest 
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareDokument: async (verfahrenDokumentRest: Array<VerfahrenDokumentRest>, planID: string, fileID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verfahrenDokumentRest' is not null or undefined
            assertParamExists('unshareDokument', 'verfahrenDokumentRest', verfahrenDokumentRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('unshareDokument', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('unshareDokument', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/dokumente/{fileID}/remove-verfahren`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verfahrenDokumentRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> update Dokument</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary update Dokument
         * @param {DokumentEditRest} dokumentEditRest neue Werte
         * @param {string} planID Plan des Verfahrens
         * @param {string} fileID FileID des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDokument: async (dokumentEditRest: DokumentEditRest, planID: string, fileID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dokumentEditRest' is not null or undefined
            assertParamExists('updateDokument', 'dokumentEditRest', dokumentEditRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('updateDokument', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('updateDokument', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/dokumente/{fileID}`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dokumentEditRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Endpunkt zum Hochladen einer neuer Dokumentversion.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Endpunkt zum Hochladen einer neuer Dokumentversion.
         * @param {File} file 
         * @param {string} planID PlanID des Verfahrens
         * @param {string} fileID FileID des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDokumentVersion: async (file: File, planID: string, fileID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('updateDokumentVersion', 'file', file)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('updateDokumentVersion', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('updateDokumentVersion', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/dokumente/{fileID}/version`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {Array<VerfahrenDokumentRest>} verfahrenDokumentRest 
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSharedDokument: async (verfahrenDokumentRest: Array<VerfahrenDokumentRest>, planID: string, fileID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'verfahrenDokumentRest' is not null or undefined
            assertParamExists('updateSharedDokument', 'verfahrenDokumentRest', verfahrenDokumentRest)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('updateSharedDokument', 'planID', planID)
            // verify required parameter 'fileID' is not null or undefined
            assertParamExists('updateSharedDokument', 'fileID', fileID)
            const localVarPath = `/verfahren/{planID}/dokumente/{fileID}/update-verfahren`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)))
                .replace(`{${"fileID"}}`, encodeURIComponent(String(fileID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(verfahrenDokumentRest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Endpunkt zum Upload eines Dokuments in die Dokumentenbibliothek.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Endpunkt zum Upload eines Dokuments in die Dokumentenbibliothek.
         * @param {DokumentNeuRest} dokument 
         * @param {File} file 
         * @param {string} planID planID des Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDokument: async (dokument: DokumentNeuRest, file: File, planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dokument' is not null or undefined
            assertParamExists('uploadDokument', 'dokument', dokument)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadDokument', 'file', file)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('uploadDokument', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/dokumente/upload`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (dokument !== undefined) { 
                localVarFormParams.append('dokument', new Blob([JSON.stringify(dokument)], { type: "application/json", }));
            }
    
            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Endpunkt zum Upload von Dokumenten in die Dokumentenbibliothek.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Endpunkt zum Upload von Dokumenten in die Dokumentenbibliothek.
         * @param {Array<DokumentNeuRest>} dokumente 
         * @param {Array<File>} files 
         * @param {string} planID planID des Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDokumente: async (dokumente: Array<DokumentNeuRest>, files: Array<File>, planID: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dokumente' is not null or undefined
            assertParamExists('uploadDokumente', 'dokumente', dokumente)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadDokumente', 'files', files)
            // verify required parameter 'planID' is not null or undefined
            assertParamExists('uploadDokumente', 'planID', planID)
            const localVarPath = `/verfahren/{planID}/dokumente`
                .replace(`{${"planID"}}`, encodeURIComponent(String(planID)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dokumente) {
                localVarFormParams.append('dokumente', dokumente.join(COLLECTION_FORMATS.csv));
            }

                if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DokumentResourceApi - functional programming interface
 * @export
 */
export const DokumentResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DokumentResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Loesche an mehrenen Dokumenten den Dokumentstatus (5694).</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Loesche an mehrenen Dokumenten den Dokumentstatus (5694).
         * @param {string} planID planID des Verfahrens
         * @param {string} codeDokumentstatus des zu loeschenden Dokumentstatus
         * @param {Array<string>} fileIDs fileIds der Dokumente
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDokumentenStatus(planID: string, codeDokumentstatus: string, fileIDs: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetDokumentDetail200Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDokumentenStatus(planID, codeDokumentstatus, fileIDs, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.deleteDokumentenStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Loeschen aller Dokumente, Dokumentstatus 1800 (Papierkorb). Entgültiges Loeschen von Dokumenten mit Dokumentstatus 1800 am  Verfahren.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Loeschen aller Dokumente, Dokumentstatus 1800 (Papierkorb).
         * @param {string} planID PlanID des Verfahrens
         * @param {boolean} [force] force wenn Dokment final gelöscht werden soll.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteForceAllDokumenteOrSetDokumentStatusGeloescht(planID: string, force?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteForceAllDokumenteOrSetDokumentStatusGeloescht(planID, force, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.deleteForceAllDokumenteOrSetDokumentStatusGeloescht']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Loeschen eines Dokuments, Dokumentstatus 1800 (Papierkorb). Entgültiges Loeschen von Dokumenten mit Dokumentstatus 1800 am  Verfahren.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Loeschen eines Dokuments, Dokumentstatus 1800 (Papierkorb).
         * @param {string} planID PlanID des Verfahrens
         * @param {string} fileID FileID des Dokuments
         * @param {boolean} [force] force wenn Dokment final gelöscht werden soll.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteForceSingleDokumentOrSetDokumentStatusGeloescht(planID: string, fileID: string, force?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteForceSingleDokumentOrSetDokumentStatusGeloescht(planID, fileID, force, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.deleteForceSingleDokumentOrSetDokumentStatusGeloescht']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} verfahrenNameInfo 
         * @param {string} fileID 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadVeroeffentlichtesDokument(verfahrenNameInfo: string, fileID: string, fileName: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadVeroeffentlichtesDokument(verfahrenNameInfo, fileID, fileName, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.downloadVeroeffentlichtesDokument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Erzeugt eine Datei aus einer Vorlage, welche anhand eines Code-Musterdokuments ermittelt wird. Im default-Fall wird ein  Word-Dokument generiert, optional kann mit dem Parameter <code>format</code> die Erzeugung eines PDF-Typs festgelegt werden. Im  speziellen wird damit das GA-Papier generiert. Das Dokument wird nicht in der Dokumentenbibliothek gespeichert, sondern  zum Browser gestreamt.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Erzeugt eine Datei aus einer Vorlage, welche anhand eines Code-Musterdokuments ermittelt wird.
         * @param {string} planID planID des Verfahrens
         * @param {string} codeMusterdokument Code-Musterdokument
         * @param {string} [format] Optionale Angabe eines PDF-Typs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erzeugeDokumentDateiAusSystemparameterGefilterterVorlage(planID: string, codeMusterdokument: string, format?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erzeugeDokumentDateiAusSystemparameterGefilterterVorlage(planID, codeMusterdokument, format, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.erzeugeDokumentDateiAusSystemparameterGefilterterVorlage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} codeMusterdokument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erzeugeDokumentDateiAusVorlage(planID: string, codeMusterdokument: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDokumentDetail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erzeugeDokumentDateiAusVorlage(planID, codeMusterdokument, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.erzeugeDokumentDateiAusVorlage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} codeMusterdokument 
         * @param {string} codeVerfahrensteilschritte 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erzeugeDokumentDateiAusVorlage1(planID: string, codeMusterdokument: string, codeVerfahrensteilschritte: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDokumentDetail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erzeugeDokumentDateiAusVorlage1(planID, codeMusterdokument, codeVerfahrensteilschritte, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.erzeugeDokumentDateiAusVorlage1']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> erzeugt Ordner als zip</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary erzeugt Ordner als zip
         * @param {string} planID planID
         * @param {string} ordnername ordnername
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erzeugeOrdner(planID: string, ordnername: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erzeugeOrdner(planID, ordnername, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.erzeugeOrdner']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert eine Liste der ActionItems welche auf das Dokument referenzieren.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @summary Liefert eine Liste der ActionItems welche auf das Dokument referenzieren.
         * @param {string} planID die planID des  Verfahrens
         * @param {string} fileID die fileID des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findActionItems(planID: string, fileID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FindActionItem200Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findActionItems(planID, fileID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.findActionItems']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Download eines Binary  <p>  Antwort der K1 auf diesen Endpoint bitte noch den Header Content-Disposition = attachment; filename=“datei_xyz.endung“  <p>  Annotation @Transactional wird hier verwendet, weil auf einen LOB zugegriffen wird, der auf verschiedene reocrds verteilt  sein kann.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Download eines Binary  
         * @param {string} planID planID
         * @param {string} fileID dokument.getFileId
         * @param {string} [version] version
         * @param {string} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDokumentDatei(planID: string, fileID: string, version?: string, format?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDokumentDatei(planID, fileID, version, format, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.getDokumentDatei']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert die Metadaten zu einem Dokument.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Liefert die Metadaten zu einem Dokument.
         * @param {string} planID Plan des Verfahrens
         * @param {string} fileID FileID des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDokumentDetail(planID: string, fileID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDokumentDetail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDokumentDetail(planID, fileID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.getDokumentDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Auflistung aller Dokumente des Verfahrens.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Auflistung aller Dokumente des Verfahrens.
         * @param {string} planID planID des {@link Verfahren Verfahren}s
         * @param {string} [suchBegriff] suchBegriff
         * @param {Array<string>} [dateiNamen] dateiName
         * @param {Array<string>} [bearbeiter] bearbeiter
         * @param {Array<string>} [datum] datum
         * @param {Array<string>} [codeDokumentstatus] codeDokumentstatus
         * @param {Array<string>} [versionen] version
         * @param {Array<CodeVormerkung>} [codeVormerkung] codeVormerkung
         * @param {Array<CodeVerfahrensunterlagetyp>} [codeVerfahrensunterlagetyp] codeVerfahrensunterlagetyp
         * @param {Array<string>} [fileID] fileIDs
         * @param {Array<GetDokumentUebersichtKlassifizierung>} [klassifizierung] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDokumentUebersicht(planID: string, suchBegriff?: string, dateiNamen?: Array<string>, bearbeiter?: Array<string>, datum?: Array<string>, codeDokumentstatus?: Array<string>, versionen?: Array<string>, codeVormerkung?: Array<CodeVormerkung>, codeVerfahrensunterlagetyp?: Array<CodeVerfahrensunterlagetyp>, fileID?: Array<string>, klassifizierung?: Array<GetDokumentUebersichtKlassifizierung>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetDokumentDetail200Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDokumentUebersicht(planID, suchBegriff, dateiNamen, bearbeiter, datum, codeDokumentstatus, versionen, codeVormerkung, codeVerfahrensunterlagetyp, fileID, klassifizierung, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.getDokumentUebersicht']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Abrufen einer Filterbelegung.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Abrufen einer Filterbelegung.
         * @param {string} planID PlanID des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilterbelegung(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumentSucheFilter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilterbelegung(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.getFilterbelegung']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Hört auf Server-Sent-Events mit {@link DokumentRest DokumentRest} als data.  Die Events werden bei Updates von Dokumenten durch WebDav gesendet</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Hört auf Server-Sent-Events mit {@link DokumentRest DokumentRest} als data.
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listenForDokumentUpdates(planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DokumentRest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listenForDokumentUpdates(planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.listenForDokumentUpdates']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Löscht eine Vormerkung an einem Dokument.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Löscht eine Vormerkung an einem Dokument.
         * @param {string} planID planID des Verfahrens
         * @param {string} fileID fileID des Dokuments
         * @param {string} codeVormerkung die zu löschende Vormerkung
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loescheDokumentVormerkung(planID: string, fileID: string, codeVormerkung: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDokumentDetail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loescheDokumentVormerkung(planID, fileID, codeVormerkung, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.loescheDokumentVormerkung']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishDokument(planID: string, fileID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDokumentDetail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishDokument(planID, fileID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.publishDokument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Setzt an einer Liste von fileIDs eine übergebene Vormerkung</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Setzt an einer Liste von fileIDs eine übergebene Vormerkung
         * @param {DokumentVormerkungRest} dokumentVormerkungRest Liste der ID\&#39;s und die Vormerkung
         * @param {string} planID planID des Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setDokumentVormerkung(dokumentVormerkungRest: DokumentVormerkungRest, planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetDokumentDetail200Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDokumentVormerkung(dokumentVormerkungRest, planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.setDokumentVormerkung']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Setzt an einem Dokument den Dokumentstatus.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Setzt an einem Dokument den Dokumentstatus.
         * @param {string} planID planID des Verfahrens
         * @param {string} fileID fileId des Dokuments
         * @param {string} codeDokumentstatus der zu setzende Dokumentstatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setzeDokumentStatus(planID: string, fileID: string, codeDokumentstatus: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDokumentDetail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setzeDokumentStatus(planID, fileID, codeDokumentstatus, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.setzeDokumentStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Setzt an mehrenen Dokumenten den Dokumentstatus (5694).</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Setzt an mehrenen Dokumenten den Dokumentstatus (5694).
         * @param {string} planID planID des Verfahrens
         * @param {string} codeDokumentstatus der zu setzende Dokumentstatus
         * @param {Array<string>} fileIDs fileIds der Dokumente
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setzeDokumentenStatus(planID: string, codeDokumentstatus: string, fileIDs: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetDokumentDetail200Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setzeDokumentenStatus(planID, codeDokumentstatus, fileIDs, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.setzeDokumentenStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {Array<VerfahrenDokumentRest>} verfahrenDokumentRest 
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shareDokument(verfahrenDokumentRest: Array<VerfahrenDokumentRest>, planID: string, fileID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDokumentDetail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shareDokument(verfahrenDokumentRest, planID, fileID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.shareDokument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unpublishDokument(planID: string, fileID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDokumentDetail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unpublishDokument(planID, fileID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.unpublishDokument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {Array<VerfahrenDokumentRest>} verfahrenDokumentRest 
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unshareDokument(verfahrenDokumentRest: Array<VerfahrenDokumentRest>, planID: string, fileID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDokumentDetail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unshareDokument(verfahrenDokumentRest, planID, fileID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.unshareDokument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> update Dokument</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary update Dokument
         * @param {DokumentEditRest} dokumentEditRest neue Werte
         * @param {string} planID Plan des Verfahrens
         * @param {string} fileID FileID des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDokument(dokumentEditRest: DokumentEditRest, planID: string, fileID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDokumentDetail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDokument(dokumentEditRest, planID, fileID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.updateDokument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Endpunkt zum Hochladen einer neuer Dokumentversion.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Endpunkt zum Hochladen einer neuer Dokumentversion.
         * @param {File} file 
         * @param {string} planID PlanID des Verfahrens
         * @param {string} fileID FileID des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDokumentVersion(file: File, planID: string, fileID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDokumentDetail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDokumentVersion(file, planID, fileID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.updateDokumentVersion']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {Array<VerfahrenDokumentRest>} verfahrenDokumentRest 
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSharedDokument(verfahrenDokumentRest: Array<VerfahrenDokumentRest>, planID: string, fileID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDokumentDetail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSharedDokument(verfahrenDokumentRest, planID, fileID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.updateSharedDokument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Endpunkt zum Upload eines Dokuments in die Dokumentenbibliothek.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Endpunkt zum Upload eines Dokuments in die Dokumentenbibliothek.
         * @param {DokumentNeuRest} dokument 
         * @param {File} file 
         * @param {string} planID planID des Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDokument(dokument: DokumentNeuRest, file: File, planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDokumentDetail200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDokument(dokument, file, planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.uploadDokument']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Endpunkt zum Upload von Dokumenten in die Dokumentenbibliothek.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Endpunkt zum Upload von Dokumenten in die Dokumentenbibliothek.
         * @param {Array<DokumentNeuRest>} dokumente 
         * @param {Array<File>} files 
         * @param {string} planID planID des Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadDokumente(dokumente: Array<DokumentNeuRest>, files: Array<File>, planID: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetDokumentDetail200Response>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadDokumente(dokumente, files, planID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DokumentResourceApi.uploadDokumente']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DokumentResourceApi - factory interface
 * @export
 */
export const DokumentResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DokumentResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Loesche an mehrenen Dokumenten den Dokumentstatus (5694).</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Loesche an mehrenen Dokumenten den Dokumentstatus (5694).
         * @param {string} planID planID des Verfahrens
         * @param {string} codeDokumentstatus des zu loeschenden Dokumentstatus
         * @param {Array<string>} fileIDs fileIds der Dokumente
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDokumentenStatus(planID: string, codeDokumentstatus: string, fileIDs: Array<string>, options?: any): AxiosPromise<Array<GetDokumentDetail200Response>> {
            return localVarFp.deleteDokumentenStatus(planID, codeDokumentstatus, fileIDs, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Loeschen aller Dokumente, Dokumentstatus 1800 (Papierkorb). Entgültiges Loeschen von Dokumenten mit Dokumentstatus 1800 am  Verfahren.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Loeschen aller Dokumente, Dokumentstatus 1800 (Papierkorb).
         * @param {string} planID PlanID des Verfahrens
         * @param {boolean} [force] force wenn Dokment final gelöscht werden soll.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteForceAllDokumenteOrSetDokumentStatusGeloescht(planID: string, force?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.deleteForceAllDokumenteOrSetDokumentStatusGeloescht(planID, force, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Loeschen eines Dokuments, Dokumentstatus 1800 (Papierkorb). Entgültiges Loeschen von Dokumenten mit Dokumentstatus 1800 am  Verfahren.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Loeschen eines Dokuments, Dokumentstatus 1800 (Papierkorb).
         * @param {string} planID PlanID des Verfahrens
         * @param {string} fileID FileID des Dokuments
         * @param {boolean} [force] force wenn Dokment final gelöscht werden soll.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteForceSingleDokumentOrSetDokumentStatusGeloescht(planID: string, fileID: string, force?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.deleteForceSingleDokumentOrSetDokumentStatusGeloescht(planID, fileID, force, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} verfahrenNameInfo 
         * @param {string} fileID 
         * @param {string} fileName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadVeroeffentlichtesDokument(verfahrenNameInfo: string, fileID: string, fileName: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadVeroeffentlichtesDokument(verfahrenNameInfo, fileID, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Erzeugt eine Datei aus einer Vorlage, welche anhand eines Code-Musterdokuments ermittelt wird. Im default-Fall wird ein  Word-Dokument generiert, optional kann mit dem Parameter <code>format</code> die Erzeugung eines PDF-Typs festgelegt werden. Im  speziellen wird damit das GA-Papier generiert. Das Dokument wird nicht in der Dokumentenbibliothek gespeichert, sondern  zum Browser gestreamt.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Erzeugt eine Datei aus einer Vorlage, welche anhand eines Code-Musterdokuments ermittelt wird.
         * @param {string} planID planID des Verfahrens
         * @param {string} codeMusterdokument Code-Musterdokument
         * @param {string} [format] Optionale Angabe eines PDF-Typs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erzeugeDokumentDateiAusSystemparameterGefilterterVorlage(planID: string, codeMusterdokument: string, format?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.erzeugeDokumentDateiAusSystemparameterGefilterterVorlage(planID, codeMusterdokument, format, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} codeMusterdokument 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erzeugeDokumentDateiAusVorlage(planID: string, codeMusterdokument: string, options?: any): AxiosPromise<GetDokumentDetail200Response> {
            return localVarFp.erzeugeDokumentDateiAusVorlage(planID, codeMusterdokument, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} codeMusterdokument 
         * @param {string} codeVerfahrensteilschritte 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erzeugeDokumentDateiAusVorlage1(planID: string, codeMusterdokument: string, codeVerfahrensteilschritte: string, options?: any): AxiosPromise<GetDokumentDetail200Response> {
            return localVarFp.erzeugeDokumentDateiAusVorlage1(planID, codeMusterdokument, codeVerfahrensteilschritte, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> erzeugt Ordner als zip</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary erzeugt Ordner als zip
         * @param {string} planID planID
         * @param {string} ordnername ordnername
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erzeugeOrdner(planID: string, ordnername: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.erzeugeOrdner(planID, ordnername, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert eine Liste der ActionItems welche auf das Dokument referenzieren.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
         * @summary Liefert eine Liste der ActionItems welche auf das Dokument referenzieren.
         * @param {string} planID die planID des  Verfahrens
         * @param {string} fileID die fileID des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findActionItems(planID: string, fileID: string, options?: any): AxiosPromise<Array<FindActionItem200Response>> {
            return localVarFp.findActionItems(planID, fileID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Download eines Binary  <p>  Antwort der K1 auf diesen Endpoint bitte noch den Header Content-Disposition = attachment; filename=“datei_xyz.endung“  <p>  Annotation @Transactional wird hier verwendet, weil auf einen LOB zugegriffen wird, der auf verschiedene reocrds verteilt  sein kann.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Download eines Binary  
         * @param {string} planID planID
         * @param {string} fileID dokument.getFileId
         * @param {string} [version] version
         * @param {string} [format] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumentDatei(planID: string, fileID: string, version?: string, format?: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getDokumentDatei(planID, fileID, version, format, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert die Metadaten zu einem Dokument.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Liefert die Metadaten zu einem Dokument.
         * @param {string} planID Plan des Verfahrens
         * @param {string} fileID FileID des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumentDetail(planID: string, fileID: string, options?: any): AxiosPromise<GetDokumentDetail200Response> {
            return localVarFp.getDokumentDetail(planID, fileID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Auflistung aller Dokumente des Verfahrens.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Auflistung aller Dokumente des Verfahrens.
         * @param {string} planID planID des {@link Verfahren Verfahren}s
         * @param {string} [suchBegriff] suchBegriff
         * @param {Array<string>} [dateiNamen] dateiName
         * @param {Array<string>} [bearbeiter] bearbeiter
         * @param {Array<string>} [datum] datum
         * @param {Array<string>} [codeDokumentstatus] codeDokumentstatus
         * @param {Array<string>} [versionen] version
         * @param {Array<CodeVormerkung>} [codeVormerkung] codeVormerkung
         * @param {Array<CodeVerfahrensunterlagetyp>} [codeVerfahrensunterlagetyp] codeVerfahrensunterlagetyp
         * @param {Array<string>} [fileID] fileIDs
         * @param {Array<GetDokumentUebersichtKlassifizierung>} [klassifizierung] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDokumentUebersicht(planID: string, suchBegriff?: string, dateiNamen?: Array<string>, bearbeiter?: Array<string>, datum?: Array<string>, codeDokumentstatus?: Array<string>, versionen?: Array<string>, codeVormerkung?: Array<CodeVormerkung>, codeVerfahrensunterlagetyp?: Array<CodeVerfahrensunterlagetyp>, fileID?: Array<string>, klassifizierung?: Array<GetDokumentUebersichtKlassifizierung>, options?: any): AxiosPromise<Array<GetDokumentDetail200Response>> {
            return localVarFp.getDokumentUebersicht(planID, suchBegriff, dateiNamen, bearbeiter, datum, codeDokumentstatus, versionen, codeVormerkung, codeVerfahrensunterlagetyp, fileID, klassifizierung, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Abrufen einer Filterbelegung.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Abrufen einer Filterbelegung.
         * @param {string} planID PlanID des Verfahrens
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterbelegung(planID: string, options?: any): AxiosPromise<DokumentSucheFilter> {
            return localVarFp.getFilterbelegung(planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Hört auf Server-Sent-Events mit {@link DokumentRest DokumentRest} als data.  Die Events werden bei Updates von Dokumenten durch WebDav gesendet</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
         * @summary Hört auf Server-Sent-Events mit {@link DokumentRest DokumentRest} als data.
         * @param {string} planID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listenForDokumentUpdates(planID: string, options?: any): AxiosPromise<DokumentRest> {
            return localVarFp.listenForDokumentUpdates(planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Löscht eine Vormerkung an einem Dokument.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Löscht eine Vormerkung an einem Dokument.
         * @param {string} planID planID des Verfahrens
         * @param {string} fileID fileID des Dokuments
         * @param {string} codeVormerkung die zu löschende Vormerkung
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loescheDokumentVormerkung(planID: string, fileID: string, codeVormerkung: string, options?: any): AxiosPromise<GetDokumentDetail200Response> {
            return localVarFp.loescheDokumentVormerkung(planID, fileID, codeVormerkung, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishDokument(planID: string, fileID: string, options?: any): AxiosPromise<GetDokumentDetail200Response> {
            return localVarFp.publishDokument(planID, fileID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Setzt an einer Liste von fileIDs eine übergebene Vormerkung</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Setzt an einer Liste von fileIDs eine übergebene Vormerkung
         * @param {DokumentVormerkungRest} dokumentVormerkungRest Liste der ID\&#39;s und die Vormerkung
         * @param {string} planID planID des Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setDokumentVormerkung(dokumentVormerkungRest: DokumentVormerkungRest, planID: string, options?: any): AxiosPromise<Array<GetDokumentDetail200Response>> {
            return localVarFp.setDokumentVormerkung(dokumentVormerkungRest, planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Setzt an einem Dokument den Dokumentstatus.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Setzt an einem Dokument den Dokumentstatus.
         * @param {string} planID planID des Verfahrens
         * @param {string} fileID fileId des Dokuments
         * @param {string} codeDokumentstatus der zu setzende Dokumentstatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setzeDokumentStatus(planID: string, fileID: string, codeDokumentstatus: string, options?: any): AxiosPromise<GetDokumentDetail200Response> {
            return localVarFp.setzeDokumentStatus(planID, fileID, codeDokumentstatus, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Setzt an mehrenen Dokumenten den Dokumentstatus (5694).</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Setzt an mehrenen Dokumenten den Dokumentstatus (5694).
         * @param {string} planID planID des Verfahrens
         * @param {string} codeDokumentstatus der zu setzende Dokumentstatus
         * @param {Array<string>} fileIDs fileIds der Dokumente
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setzeDokumentenStatus(planID: string, codeDokumentstatus: string, fileIDs: Array<string>, options?: any): AxiosPromise<Array<GetDokumentDetail200Response>> {
            return localVarFp.setzeDokumentenStatus(planID, codeDokumentstatus, fileIDs, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {Array<VerfahrenDokumentRest>} verfahrenDokumentRest 
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shareDokument(verfahrenDokumentRest: Array<VerfahrenDokumentRest>, planID: string, fileID: string, options?: any): AxiosPromise<GetDokumentDetail200Response> {
            return localVarFp.shareDokument(verfahrenDokumentRest, planID, fileID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unpublishDokument(planID: string, fileID: string, options?: any): AxiosPromise<GetDokumentDetail200Response> {
            return localVarFp.unpublishDokument(planID, fileID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {Array<VerfahrenDokumentRest>} verfahrenDokumentRest 
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unshareDokument(verfahrenDokumentRest: Array<VerfahrenDokumentRest>, planID: string, fileID: string, options?: any): AxiosPromise<GetDokumentDetail200Response> {
            return localVarFp.unshareDokument(verfahrenDokumentRest, planID, fileID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> update Dokument</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary update Dokument
         * @param {DokumentEditRest} dokumentEditRest neue Werte
         * @param {string} planID Plan des Verfahrens
         * @param {string} fileID FileID des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDokument(dokumentEditRest: DokumentEditRest, planID: string, fileID: string, options?: any): AxiosPromise<GetDokumentDetail200Response> {
            return localVarFp.updateDokument(dokumentEditRest, planID, fileID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Endpunkt zum Hochladen einer neuer Dokumentversion.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Endpunkt zum Hochladen einer neuer Dokumentversion.
         * @param {File} file 
         * @param {string} planID PlanID des Verfahrens
         * @param {string} fileID FileID des Dokuments
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDokumentVersion(file: File, planID: string, fileID: string, options?: any): AxiosPromise<GetDokumentDetail200Response> {
            return localVarFp.updateDokumentVersion(file, planID, fileID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @param {Array<VerfahrenDokumentRest>} verfahrenDokumentRest 
         * @param {string} planID 
         * @param {string} fileID 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSharedDokument(verfahrenDokumentRest: Array<VerfahrenDokumentRest>, planID: string, fileID: string, options?: any): AxiosPromise<GetDokumentDetail200Response> {
            return localVarFp.updateSharedDokument(verfahrenDokumentRest, planID, fileID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Endpunkt zum Upload eines Dokuments in die Dokumentenbibliothek.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Endpunkt zum Upload eines Dokuments in die Dokumentenbibliothek.
         * @param {DokumentNeuRest} dokument 
         * @param {File} file 
         * @param {string} planID planID des Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDokument(dokument: DokumentNeuRest, file: File, planID: string, options?: any): AxiosPromise<GetDokumentDetail200Response> {
            return localVarFp.uploadDokument(dokument, file, planID, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Endpunkt zum Upload von Dokumenten in die Dokumentenbibliothek.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
         * @summary Endpunkt zum Upload von Dokumenten in die Dokumentenbibliothek.
         * @param {Array<DokumentNeuRest>} dokumente 
         * @param {Array<File>} files 
         * @param {string} planID planID des Verfahren
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadDokumente(dokumente: Array<DokumentNeuRest>, files: Array<File>, planID: string, options?: any): AxiosPromise<Array<GetDokumentDetail200Response>> {
            return localVarFp.uploadDokumente(dokumente, files, planID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DokumentResourceApi - object-oriented interface
 * @export
 * @class DokumentResourceApi
 * @extends {BaseAPI}
 */
export class DokumentResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b> Loesche an mehrenen Dokumenten den Dokumentstatus (5694).</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @summary Loesche an mehrenen Dokumenten den Dokumentstatus (5694).
     * @param {string} planID planID des Verfahrens
     * @param {string} codeDokumentstatus des zu loeschenden Dokumentstatus
     * @param {Array<string>} fileIDs fileIds der Dokumente
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public deleteDokumentenStatus(planID: string, codeDokumentstatus: string, fileIDs: Array<string>, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).deleteDokumentenStatus(planID, codeDokumentstatus, fileIDs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Loeschen aller Dokumente, Dokumentstatus 1800 (Papierkorb). Entgültiges Loeschen von Dokumenten mit Dokumentstatus 1800 am  Verfahren.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @summary Loeschen aller Dokumente, Dokumentstatus 1800 (Papierkorb).
     * @param {string} planID PlanID des Verfahrens
     * @param {boolean} [force] force wenn Dokment final gelöscht werden soll.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public deleteForceAllDokumenteOrSetDokumentStatusGeloescht(planID: string, force?: boolean, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).deleteForceAllDokumenteOrSetDokumentStatusGeloescht(planID, force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Loeschen eines Dokuments, Dokumentstatus 1800 (Papierkorb). Entgültiges Loeschen von Dokumenten mit Dokumentstatus 1800 am  Verfahren.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @summary Loeschen eines Dokuments, Dokumentstatus 1800 (Papierkorb).
     * @param {string} planID PlanID des Verfahrens
     * @param {string} fileID FileID des Dokuments
     * @param {boolean} [force] force wenn Dokment final gelöscht werden soll.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public deleteForceSingleDokumentOrSetDokumentStatusGeloescht(planID: string, fileID: string, force?: boolean, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).deleteForceSingleDokumentOrSetDokumentStatusGeloescht(planID, fileID, force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} verfahrenNameInfo 
     * @param {string} fileID 
     * @param {string} fileName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public downloadVeroeffentlichtesDokument(verfahrenNameInfo: string, fileID: string, fileName: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).downloadVeroeffentlichtesDokument(verfahrenNameInfo, fileID, fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Erzeugt eine Datei aus einer Vorlage, welche anhand eines Code-Musterdokuments ermittelt wird. Im default-Fall wird ein  Word-Dokument generiert, optional kann mit dem Parameter <code>format</code> die Erzeugung eines PDF-Typs festgelegt werden. Im  speziellen wird damit das GA-Papier generiert. Das Dokument wird nicht in der Dokumentenbibliothek gespeichert, sondern  zum Browser gestreamt.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
     * @summary Erzeugt eine Datei aus einer Vorlage, welche anhand eines Code-Musterdokuments ermittelt wird.
     * @param {string} planID planID des Verfahrens
     * @param {string} codeMusterdokument Code-Musterdokument
     * @param {string} [format] Optionale Angabe eines PDF-Typs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public erzeugeDokumentDateiAusSystemparameterGefilterterVorlage(planID: string, codeMusterdokument: string, format?: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).erzeugeDokumentDateiAusSystemparameterGefilterterVorlage(planID, codeMusterdokument, format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @param {string} planID 
     * @param {string} codeMusterdokument 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public erzeugeDokumentDateiAusVorlage(planID: string, codeMusterdokument: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).erzeugeDokumentDateiAusVorlage(planID, codeMusterdokument, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @param {string} planID 
     * @param {string} codeMusterdokument 
     * @param {string} codeVerfahrensteilschritte 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public erzeugeDokumentDateiAusVorlage1(planID: string, codeMusterdokument: string, codeVerfahrensteilschritte: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).erzeugeDokumentDateiAusVorlage1(planID, codeMusterdokument, codeVerfahrensteilschritte, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> erzeugt Ordner als zip</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
     * @summary erzeugt Ordner als zip
     * @param {string} planID planID
     * @param {string} ordnername ordnername
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public erzeugeOrdner(planID: string, ordnername: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).erzeugeOrdner(planID, ordnername, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert eine Liste der ActionItems welche auf das Dokument referenzieren.</br></br><b>Benötigte Rechte:</b> VERFAHRENSSTEUERUNG_READ or ADMIN
     * @summary Liefert eine Liste der ActionItems welche auf das Dokument referenzieren.
     * @param {string} planID die planID des  Verfahrens
     * @param {string} fileID die fileID des Dokuments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public findActionItems(planID: string, fileID: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).findActionItems(planID, fileID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Download eines Binary  <p>  Antwort der K1 auf diesen Endpoint bitte noch den Header Content-Disposition = attachment; filename=“datei_xyz.endung“  <p>  Annotation @Transactional wird hier verwendet, weil auf einen LOB zugegriffen wird, der auf verschiedene reocrds verteilt  sein kann.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
     * @summary Download eines Binary  
     * @param {string} planID planID
     * @param {string} fileID dokument.getFileId
     * @param {string} [version] version
     * @param {string} [format] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public getDokumentDatei(planID: string, fileID: string, version?: string, format?: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).getDokumentDatei(planID, fileID, version, format, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert die Metadaten zu einem Dokument.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
     * @summary Liefert die Metadaten zu einem Dokument.
     * @param {string} planID Plan des Verfahrens
     * @param {string} fileID FileID des Dokuments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public getDokumentDetail(planID: string, fileID: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).getDokumentDetail(planID, fileID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Auflistung aller Dokumente des Verfahrens.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
     * @summary Auflistung aller Dokumente des Verfahrens.
     * @param {string} planID planID des {@link Verfahren Verfahren}s
     * @param {string} [suchBegriff] suchBegriff
     * @param {Array<string>} [dateiNamen] dateiName
     * @param {Array<string>} [bearbeiter] bearbeiter
     * @param {Array<string>} [datum] datum
     * @param {Array<string>} [codeDokumentstatus] codeDokumentstatus
     * @param {Array<string>} [versionen] version
     * @param {Array<CodeVormerkung>} [codeVormerkung] codeVormerkung
     * @param {Array<CodeVerfahrensunterlagetyp>} [codeVerfahrensunterlagetyp] codeVerfahrensunterlagetyp
     * @param {Array<string>} [fileID] fileIDs
     * @param {Array<GetDokumentUebersichtKlassifizierung>} [klassifizierung] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public getDokumentUebersicht(planID: string, suchBegriff?: string, dateiNamen?: Array<string>, bearbeiter?: Array<string>, datum?: Array<string>, codeDokumentstatus?: Array<string>, versionen?: Array<string>, codeVormerkung?: Array<CodeVormerkung>, codeVerfahrensunterlagetyp?: Array<CodeVerfahrensunterlagetyp>, fileID?: Array<string>, klassifizierung?: Array<GetDokumentUebersichtKlassifizierung>, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).getDokumentUebersicht(planID, suchBegriff, dateiNamen, bearbeiter, datum, codeDokumentstatus, versionen, codeVormerkung, codeVerfahrensunterlagetyp, fileID, klassifizierung, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Abrufen einer Filterbelegung.</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
     * @summary Abrufen einer Filterbelegung.
     * @param {string} planID PlanID des Verfahrens
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public getFilterbelegung(planID: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).getFilterbelegung(planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Hört auf Server-Sent-Events mit {@link DokumentRest DokumentRest} als data.  Die Events werden bei Updates von Dokumenten durch WebDav gesendet</br></br><b>Benötigte Rechte:</b> DOKUMENT_READ or EIGENE_DOKUMENT_READ or ADMIN
     * @summary Hört auf Server-Sent-Events mit {@link DokumentRest DokumentRest} als data.
     * @param {string} planID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public listenForDokumentUpdates(planID: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).listenForDokumentUpdates(planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Löscht eine Vormerkung an einem Dokument.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @summary Löscht eine Vormerkung an einem Dokument.
     * @param {string} planID planID des Verfahrens
     * @param {string} fileID fileID des Dokuments
     * @param {string} codeVormerkung die zu löschende Vormerkung
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public loescheDokumentVormerkung(planID: string, fileID: string, codeVormerkung: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).loescheDokumentVormerkung(planID, fileID, codeVormerkung, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @param {string} planID 
     * @param {string} fileID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public publishDokument(planID: string, fileID: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).publishDokument(planID, fileID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Setzt an einer Liste von fileIDs eine übergebene Vormerkung</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @summary Setzt an einer Liste von fileIDs eine übergebene Vormerkung
     * @param {DokumentVormerkungRest} dokumentVormerkungRest Liste der ID\&#39;s und die Vormerkung
     * @param {string} planID planID des Verfahren
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public setDokumentVormerkung(dokumentVormerkungRest: DokumentVormerkungRest, planID: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).setDokumentVormerkung(dokumentVormerkungRest, planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Setzt an einem Dokument den Dokumentstatus.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @summary Setzt an einem Dokument den Dokumentstatus.
     * @param {string} planID planID des Verfahrens
     * @param {string} fileID fileId des Dokuments
     * @param {string} codeDokumentstatus der zu setzende Dokumentstatus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public setzeDokumentStatus(planID: string, fileID: string, codeDokumentstatus: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).setzeDokumentStatus(planID, fileID, codeDokumentstatus, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Setzt an mehrenen Dokumenten den Dokumentstatus (5694).</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @summary Setzt an mehrenen Dokumenten den Dokumentstatus (5694).
     * @param {string} planID planID des Verfahrens
     * @param {string} codeDokumentstatus der zu setzende Dokumentstatus
     * @param {Array<string>} fileIDs fileIds der Dokumente
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public setzeDokumentenStatus(planID: string, codeDokumentstatus: string, fileIDs: Array<string>, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).setzeDokumentenStatus(planID, codeDokumentstatus, fileIDs, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @param {Array<VerfahrenDokumentRest>} verfahrenDokumentRest 
     * @param {string} planID 
     * @param {string} fileID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public shareDokument(verfahrenDokumentRest: Array<VerfahrenDokumentRest>, planID: string, fileID: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).shareDokument(verfahrenDokumentRest, planID, fileID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @param {string} planID 
     * @param {string} fileID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public unpublishDokument(planID: string, fileID: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).unpublishDokument(planID, fileID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @param {Array<VerfahrenDokumentRest>} verfahrenDokumentRest 
     * @param {string} planID 
     * @param {string} fileID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public unshareDokument(verfahrenDokumentRest: Array<VerfahrenDokumentRest>, planID: string, fileID: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).unshareDokument(verfahrenDokumentRest, planID, fileID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> update Dokument</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @summary update Dokument
     * @param {DokumentEditRest} dokumentEditRest neue Werte
     * @param {string} planID Plan des Verfahrens
     * @param {string} fileID FileID des Dokuments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public updateDokument(dokumentEditRest: DokumentEditRest, planID: string, fileID: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).updateDokument(dokumentEditRest, planID, fileID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Endpunkt zum Hochladen einer neuer Dokumentversion.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @summary Endpunkt zum Hochladen einer neuer Dokumentversion.
     * @param {File} file 
     * @param {string} planID PlanID des Verfahrens
     * @param {string} fileID FileID des Dokuments
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public updateDokumentVersion(file: File, planID: string, fileID: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).updateDokumentVersion(file, planID, fileID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @param {Array<VerfahrenDokumentRest>} verfahrenDokumentRest 
     * @param {string} planID 
     * @param {string} fileID 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public updateSharedDokument(verfahrenDokumentRest: Array<VerfahrenDokumentRest>, planID: string, fileID: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).updateSharedDokument(verfahrenDokumentRest, planID, fileID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Endpunkt zum Upload eines Dokuments in die Dokumentenbibliothek.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @summary Endpunkt zum Upload eines Dokuments in die Dokumentenbibliothek.
     * @param {DokumentNeuRest} dokument 
     * @param {File} file 
     * @param {string} planID planID des Verfahren
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public uploadDokument(dokument: DokumentNeuRest, file: File, planID: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).uploadDokument(dokument, file, planID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Endpunkt zum Upload von Dokumenten in die Dokumentenbibliothek.</br></br><b>Benötigte Rechte:</b> DOKUMENT_WRITE or EIGENE_DOKUMENT_WRITE or ADMIN
     * @summary Endpunkt zum Upload von Dokumenten in die Dokumentenbibliothek.
     * @param {Array<DokumentNeuRest>} dokumente 
     * @param {Array<File>} files 
     * @param {string} planID planID des Verfahren
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DokumentResourceApi
     */
    public uploadDokumente(dokumente: Array<DokumentNeuRest>, files: Array<File>, planID: string, options?: RawAxiosRequestConfig) {
        return DokumentResourceApiFp(this.configuration).uploadDokumente(dokumente, files, planID, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetDokumentUebersichtKlassifizierung = {
    VERFAHREN: 'VERFAHREN',
    STELLUNGNAHME: 'STELLUNGNAHME'
} as const;
export type GetDokumentUebersichtKlassifizierung = typeof GetDokumentUebersichtKlassifizierung[keyof typeof GetDokumentUebersichtKlassifizierung];
