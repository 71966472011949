/* tslint:disable */
/* eslint-disable */
/**
 * K1 OpenAPI
 * API fuer die Nutzung der K1 REST-Schnittstelle
 *
 * The version of the OpenAPI document: 1.140.0
 * Contact: diplan-backend@init.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ErrorRest } from '../models';
// @ts-ignore
import { WhitelistTerminRest } from '../models';
// @ts-ignore
import { WhitelistTerminSerieRest } from '../models';
/**
 * WhitelistResourceApi - axios parameter creator
 * @export
 */
export const WhitelistResourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * <b>Beschreibung:</b> Löscht Whitelist-Termine</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Löscht Whitelist-Termine
         * @param {Array<number>} requestBody id\&#39;s der Termine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWhitelist: async (requestBody: Array<number>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteWhitelist', 'requestBody', requestBody)
            const localVarPath = `/konfigurationen/whitelist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Löscht eine Whitelist-Terminserie</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Löscht eine Whitelist-Terminserie
         * @param {string} zustaendigkeit zustaendigkeit
         * @param {string} sitzungscode Sitzungscode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWhitelistSerie: async (zustaendigkeit: string, sitzungscode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zustaendigkeit' is not null or undefined
            assertParamExists('deleteWhitelistSerie', 'zustaendigkeit', zustaendigkeit)
            // verify required parameter 'sitzungscode' is not null or undefined
            assertParamExists('deleteWhitelistSerie', 'sitzungscode', sitzungscode)
            const localVarPath = `/konfigurationen/whitelistserie/zustaendigkeit/{zustaendigkeit}/sitzungscode/{sitzungscode}`
                .replace(`{${"zustaendigkeit"}}`, encodeURIComponent(String(zustaendigkeit)))
                .replace(`{${"sitzungscode"}}`, encodeURIComponent(String(sitzungscode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Download einer Whitelist-Terminserie</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Download einer Whitelist-Terminserie
         * @param {string} zustaendigkeit Zustaendigkeit
         * @param {string} sitzungscode Sitzungscode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWhitelistSerie: async (zustaendigkeit: string, sitzungscode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zustaendigkeit' is not null or undefined
            assertParamExists('downloadWhitelistSerie', 'zustaendigkeit', zustaendigkeit)
            // verify required parameter 'sitzungscode' is not null or undefined
            assertParamExists('downloadWhitelistSerie', 'sitzungscode', sitzungscode)
            const localVarPath = `/konfigurationen/whitelistserie/zustaendigkeit/{zustaendigkeit}/sitzungscode/{sitzungscode}/datei`
                .replace(`{${"zustaendigkeit"}}`, encodeURIComponent(String(zustaendigkeit)))
                .replace(`{${"sitzungscode"}}`, encodeURIComponent(String(sitzungscode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert alle Whitelist-Termine aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert alle Whitelist-Termine aus.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhitelist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/whitelist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert die Whitelist-Termine nach Zustaendigkeit aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert die Whitelist-Termine nach Zustaendigkeit aus.
         * @param {string} zustaendigkeit zustaendigkeit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhitelistByZustaendigkeit: async (zustaendigkeit: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zustaendigkeit' is not null or undefined
            assertParamExists('getWhitelistByZustaendigkeit', 'zustaendigkeit', zustaendigkeit)
            const localVarPath = `/konfigurationen/whitelist/zustaendigkeit/{zustaendigkeit}`
                .replace(`{${"zustaendigkeit"}}`, encodeURIComponent(String(zustaendigkeit)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert die Whitelist-Termine nach Zustaendigkeit und Sitzungcode aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert die Whitelist-Termine nach Zustaendigkeit und Sitzungcode aus.
         * @param {string} zustaendigkeit zustaendigkeit
         * @param {string} sitzungscode sitzungscode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhitelistByZustaendigkeitBySitzungscode: async (zustaendigkeit: string, sitzungscode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'zustaendigkeit' is not null or undefined
            assertParamExists('getWhitelistByZustaendigkeitBySitzungscode', 'zustaendigkeit', zustaendigkeit)
            // verify required parameter 'sitzungscode' is not null or undefined
            assertParamExists('getWhitelistByZustaendigkeitBySitzungscode', 'sitzungscode', sitzungscode)
            const localVarPath = `/konfigurationen/whitelist/zustaendigkeit/{zustaendigkeit}/sitzungscode/{sitzungscode}`
                .replace(`{${"zustaendigkeit"}}`, encodeURIComponent(String(zustaendigkeit)))
                .replace(`{${"sitzungscode"}}`, encodeURIComponent(String(sitzungscode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> Liefert die Termin-Serie groupiert nach Zustaendigkeit und Sitzungscode aus</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert die Termin-Serie groupiert nach Zustaendigkeit und Sitzungscode aus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhitelistSerie: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/konfigurationen/whitelistserie`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * <b>Beschreibung:</b> upload whitelist ics-Datei</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary upload whitelist ics-Datei
         * @param {File} whitelist 
         * @param {string} zustaendigkeit zustaendigkeit
         * @param {string} sitzungscode sitzungscode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadWhitelist: async (whitelist: File, zustaendigkeit: string, sitzungscode: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'whitelist' is not null or undefined
            assertParamExists('uploadWhitelist', 'whitelist', whitelist)
            // verify required parameter 'zustaendigkeit' is not null or undefined
            assertParamExists('uploadWhitelist', 'zustaendigkeit', zustaendigkeit)
            // verify required parameter 'sitzungscode' is not null or undefined
            assertParamExists('uploadWhitelist', 'sitzungscode', sitzungscode)
            const localVarPath = `/konfigurationen/whitelist/upload/zustaendigkeit/{zustaendigkeit}/sitzungscode/{sitzungscode}`
                .replace(`{${"zustaendigkeit"}}`, encodeURIComponent(String(zustaendigkeit)))
                .replace(`{${"sitzungscode"}}`, encodeURIComponent(String(sitzungscode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwt-token required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (whitelist !== undefined) { 
                localVarFormParams.append('whitelist', whitelist as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WhitelistResourceApi - functional programming interface
 * @export
 */
export const WhitelistResourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WhitelistResourceApiAxiosParamCreator(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Löscht Whitelist-Termine</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Löscht Whitelist-Termine
         * @param {Array<number>} requestBody id\&#39;s der Termine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWhitelist(requestBody: Array<number>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWhitelist(requestBody, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhitelistResourceApi.deleteWhitelist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Löscht eine Whitelist-Terminserie</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Löscht eine Whitelist-Terminserie
         * @param {string} zustaendigkeit zustaendigkeit
         * @param {string} sitzungscode Sitzungscode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteWhitelistSerie(zustaendigkeit: string, sitzungscode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteWhitelistSerie(zustaendigkeit, sitzungscode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhitelistResourceApi.deleteWhitelistSerie']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Download einer Whitelist-Terminserie</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Download einer Whitelist-Terminserie
         * @param {string} zustaendigkeit Zustaendigkeit
         * @param {string} sitzungscode Sitzungscode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadWhitelistSerie(zustaendigkeit: string, sitzungscode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadWhitelistSerie(zustaendigkeit, sitzungscode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhitelistResourceApi.downloadWhitelistSerie']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert alle Whitelist-Termine aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert alle Whitelist-Termine aus.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWhitelist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WhitelistTerminRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWhitelist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhitelistResourceApi.getWhitelist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert die Whitelist-Termine nach Zustaendigkeit aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert die Whitelist-Termine nach Zustaendigkeit aus.
         * @param {string} zustaendigkeit zustaendigkeit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWhitelistByZustaendigkeit(zustaendigkeit: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WhitelistTerminRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWhitelistByZustaendigkeit(zustaendigkeit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhitelistResourceApi.getWhitelistByZustaendigkeit']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert die Whitelist-Termine nach Zustaendigkeit und Sitzungcode aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert die Whitelist-Termine nach Zustaendigkeit und Sitzungcode aus.
         * @param {string} zustaendigkeit zustaendigkeit
         * @param {string} sitzungscode sitzungscode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWhitelistByZustaendigkeitBySitzungscode(zustaendigkeit: string, sitzungscode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WhitelistTerminRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWhitelistByZustaendigkeitBySitzungscode(zustaendigkeit, sitzungscode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhitelistResourceApi.getWhitelistByZustaendigkeitBySitzungscode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> Liefert die Termin-Serie groupiert nach Zustaendigkeit und Sitzungscode aus</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert die Termin-Serie groupiert nach Zustaendigkeit und Sitzungscode aus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWhitelistSerie(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WhitelistTerminSerieRest>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWhitelistSerie(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhitelistResourceApi.getWhitelistSerie']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * <b>Beschreibung:</b> upload whitelist ics-Datei</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary upload whitelist ics-Datei
         * @param {File} whitelist 
         * @param {string} zustaendigkeit zustaendigkeit
         * @param {string} sitzungscode sitzungscode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadWhitelist(whitelist: File, zustaendigkeit: string, sitzungscode: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadWhitelist(whitelist, zustaendigkeit, sitzungscode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WhitelistResourceApi.uploadWhitelist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WhitelistResourceApi - factory interface
 * @export
 */
export const WhitelistResourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WhitelistResourceApiFp(configuration)
    return {
        /**
         * <b>Beschreibung:</b> Löscht Whitelist-Termine</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Löscht Whitelist-Termine
         * @param {Array<number>} requestBody id\&#39;s der Termine
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWhitelist(requestBody: Array<number>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWhitelist(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Löscht eine Whitelist-Terminserie</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary Löscht eine Whitelist-Terminserie
         * @param {string} zustaendigkeit zustaendigkeit
         * @param {string} sitzungscode Sitzungscode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWhitelistSerie(zustaendigkeit: string, sitzungscode: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteWhitelistSerie(zustaendigkeit, sitzungscode, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Download einer Whitelist-Terminserie</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Download einer Whitelist-Terminserie
         * @param {string} zustaendigkeit Zustaendigkeit
         * @param {string} sitzungscode Sitzungscode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadWhitelistSerie(zustaendigkeit: string, sitzungscode: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.downloadWhitelistSerie(zustaendigkeit, sitzungscode, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert alle Whitelist-Termine aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert alle Whitelist-Termine aus.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhitelist(options?: any): AxiosPromise<Array<WhitelistTerminRest>> {
            return localVarFp.getWhitelist(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert die Whitelist-Termine nach Zustaendigkeit aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert die Whitelist-Termine nach Zustaendigkeit aus.
         * @param {string} zustaendigkeit zustaendigkeit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhitelistByZustaendigkeit(zustaendigkeit: string, options?: any): AxiosPromise<Array<WhitelistTerminRest>> {
            return localVarFp.getWhitelistByZustaendigkeit(zustaendigkeit, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert die Whitelist-Termine nach Zustaendigkeit und Sitzungcode aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert die Whitelist-Termine nach Zustaendigkeit und Sitzungcode aus.
         * @param {string} zustaendigkeit zustaendigkeit
         * @param {string} sitzungscode sitzungscode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhitelistByZustaendigkeitBySitzungscode(zustaendigkeit: string, sitzungscode: string, options?: any): AxiosPromise<Array<WhitelistTerminRest>> {
            return localVarFp.getWhitelistByZustaendigkeitBySitzungscode(zustaendigkeit, sitzungscode, options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> Liefert die Termin-Serie groupiert nach Zustaendigkeit und Sitzungscode aus</br></br><b>Benötigte Rechte:</b> isAuthenticated()
         * @summary Liefert die Termin-Serie groupiert nach Zustaendigkeit und Sitzungscode aus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWhitelistSerie(options?: any): AxiosPromise<Array<WhitelistTerminSerieRest>> {
            return localVarFp.getWhitelistSerie(options).then((request) => request(axios, basePath));
        },
        /**
         * <b>Beschreibung:</b> upload whitelist ics-Datei</br></br><b>Benötigte Rechte:</b> ADMIN
         * @summary upload whitelist ics-Datei
         * @param {File} whitelist 
         * @param {string} zustaendigkeit zustaendigkeit
         * @param {string} sitzungscode sitzungscode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadWhitelist(whitelist: File, zustaendigkeit: string, sitzungscode: string, options?: any): AxiosPromise<void> {
            return localVarFp.uploadWhitelist(whitelist, zustaendigkeit, sitzungscode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WhitelistResourceApi - object-oriented interface
 * @export
 * @class WhitelistResourceApi
 * @extends {BaseAPI}
 */
export class WhitelistResourceApi extends BaseAPI {
    /**
     * <b>Beschreibung:</b> Löscht Whitelist-Termine</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Löscht Whitelist-Termine
     * @param {Array<number>} requestBody id\&#39;s der Termine
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhitelistResourceApi
     */
    public deleteWhitelist(requestBody: Array<number>, options?: RawAxiosRequestConfig) {
        return WhitelistResourceApiFp(this.configuration).deleteWhitelist(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Löscht eine Whitelist-Terminserie</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary Löscht eine Whitelist-Terminserie
     * @param {string} zustaendigkeit zustaendigkeit
     * @param {string} sitzungscode Sitzungscode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhitelistResourceApi
     */
    public deleteWhitelistSerie(zustaendigkeit: string, sitzungscode: string, options?: RawAxiosRequestConfig) {
        return WhitelistResourceApiFp(this.configuration).deleteWhitelistSerie(zustaendigkeit, sitzungscode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Download einer Whitelist-Terminserie</br></br><b>Benötigte Rechte:</b> isAuthenticated()
     * @summary Download einer Whitelist-Terminserie
     * @param {string} zustaendigkeit Zustaendigkeit
     * @param {string} sitzungscode Sitzungscode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhitelistResourceApi
     */
    public downloadWhitelistSerie(zustaendigkeit: string, sitzungscode: string, options?: RawAxiosRequestConfig) {
        return WhitelistResourceApiFp(this.configuration).downloadWhitelistSerie(zustaendigkeit, sitzungscode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert alle Whitelist-Termine aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
     * @summary Liefert alle Whitelist-Termine aus.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhitelistResourceApi
     */
    public getWhitelist(options?: RawAxiosRequestConfig) {
        return WhitelistResourceApiFp(this.configuration).getWhitelist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert die Whitelist-Termine nach Zustaendigkeit aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
     * @summary Liefert die Whitelist-Termine nach Zustaendigkeit aus.
     * @param {string} zustaendigkeit zustaendigkeit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhitelistResourceApi
     */
    public getWhitelistByZustaendigkeit(zustaendigkeit: string, options?: RawAxiosRequestConfig) {
        return WhitelistResourceApiFp(this.configuration).getWhitelistByZustaendigkeit(zustaendigkeit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert die Whitelist-Termine nach Zustaendigkeit und Sitzungcode aus.</br></br><b>Benötigte Rechte:</b> isAuthenticated()
     * @summary Liefert die Whitelist-Termine nach Zustaendigkeit und Sitzungcode aus.
     * @param {string} zustaendigkeit zustaendigkeit
     * @param {string} sitzungscode sitzungscode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhitelistResourceApi
     */
    public getWhitelistByZustaendigkeitBySitzungscode(zustaendigkeit: string, sitzungscode: string, options?: RawAxiosRequestConfig) {
        return WhitelistResourceApiFp(this.configuration).getWhitelistByZustaendigkeitBySitzungscode(zustaendigkeit, sitzungscode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> Liefert die Termin-Serie groupiert nach Zustaendigkeit und Sitzungscode aus</br></br><b>Benötigte Rechte:</b> isAuthenticated()
     * @summary Liefert die Termin-Serie groupiert nach Zustaendigkeit und Sitzungscode aus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhitelistResourceApi
     */
    public getWhitelistSerie(options?: RawAxiosRequestConfig) {
        return WhitelistResourceApiFp(this.configuration).getWhitelistSerie(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * <b>Beschreibung:</b> upload whitelist ics-Datei</br></br><b>Benötigte Rechte:</b> ADMIN
     * @summary upload whitelist ics-Datei
     * @param {File} whitelist 
     * @param {string} zustaendigkeit zustaendigkeit
     * @param {string} sitzungscode sitzungscode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WhitelistResourceApi
     */
    public uploadWhitelist(whitelist: File, zustaendigkeit: string, sitzungscode: string, options?: RawAxiosRequestConfig) {
        return WhitelistResourceApiFp(this.configuration).uploadWhitelist(whitelist, zustaendigkeit, sitzungscode, options).then((request) => request(this.axios, this.basePath));
    }
}

